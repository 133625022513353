//----------   PICTURE    ----------//

$bgHeader: "assets/bg-header.png";
$bgBottomHeader: "assets/bottom-header.jpg";
$bgBottomHobbies: "assets/bg-footer.png";
$bgBottomRightBloc: "assets/bg-footer-right.png";

//----------  BACKGROUND  ----------//

$bg-color-1: #f5f5f5;
$bg-color-2: #303b3f;
$bg-color-3: #4bacc6;
$bg-color-4: #3690a8;
$bg-color-5: #1e1e1e;
$bg-color-6: #31464b;
$bg-color-7: #31464b;
$bg-color-8: #2c2c2c;
$bg-color-9: #a20000;

//----------     COLOR    ----------//

$text-color-1: white;
$text-color-2: #303b3f;
$text-color-3: #4bacc6;

//----------     @EACHS    ----------//

$bar: "textWindows" right $bg-color-4 $bg-color-6 90% 10%,
  "textLinux" left $bg-color-6 $bg-color-4 70% 30%,
  "textMacosx" left $bg-color-6 $bg-color-4 80% 20%,
  "textPrinter" right $bg-color-4 $bg-color-6 50% 50%,
  "textOpti" right $bg-color-4 $bg-color-6 98% 2%,
  "textPreparation" right $bg-color-4 $bg-color-6 100% 0,
  "textRemote" right $bg-color-4 $bg-color-6 90% 10%,
  "textNetwork" right $bg-color-4 $bg-color-6 50% 50%,
  "textNas" left $bg-color-6 $bg-color-4 60% 40%,
  "textFreenas" left $bg-color-6 $bg-color-4 60% 40%,
  "textHtml" right $bg-color-4 $bg-color-6 75% 25%,
  "textSass" right $bg-color-4 $bg-color-6 65% 35%,
  "textJs" left $bg-color-6 $bg-color-4 55% 45%,
  "textReact" left $bg-color-6 $bg-color-4 80% 20%,
  "textUX" right $bg-color-4 $bg-color-6 80% 20%,
  "textUI" right $bg-color-4 $bg-color-6 80% 20%,
  "textApi" left $bg-color-6 $bg-color-4 75% 25%,
  "textUserData" left $bg-color-6 $bg-color-4 75% 25%,
  "textDataBase" left $bg-color-6 $bg-color-4 75% 25%,
  "textProdSite" left $bg-color-6 $bg-color-4 55% 45%;

$charts: "photoshop" $bg-color-7 $bg-color-4 $bg-color-4 $bg-color-4,
  "word" $bg-color-7 $bg-color-4 $bg-color-4 $bg-color-4,
  "google" $bg-color-7 $bg-color-4 $bg-color-7 $bg-color-4,
  "acrobat" $bg-color-7 $bg-color-4 $bg-color-7 $bg-color-4,
  "excel" $bg-color-7 $bg-color-4 $bg-color-4 $bg-color-4,
  "vscode" $bg-color-7 $bg-color-4 $bg-color-4 $bg-color-4,
  "illustrator" $bg-color-7 $bg-color-7 $bg-color-7 $bg-color-4,
  "github" $bg-color-7 $bg-color-4 $bg-color-7 $bg-color-4;
