.bg-left-bloc {

    .bloc-text {
        z-index: 100;
    }
    
    li {
        margin: 10px 10px;
    }

    .adress {

        li {
            margin: 19px 10px;
        }
    }
}

#hobbies {
    .bg-hobbies {
        margin-bottom: 10px;
    }
    li {
        margin: 20px 0;
    }
}

