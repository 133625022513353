.all-circle,
.bg-icon-skill,
.bg-left-bloc,
.bg-left-bloc h1,
.body,
.bloc-text h1,
.charts-group,
.charts,
.charts h2,
footer,
header,
#hobbies,
.iconPrint,
.left-bloc,
.left-subtitle h2,
main,
.right-subtitle,
section,
.subtitle,
.text,
.text-error-page,
.text-hobbies,
.bg-header,
.title {
  display: flex;
}

.all-circle,
.charts-group,
.text {
  flex-flow: row wrap;
}

main,
.subtitle,
.text-error-page,
.title {
  flex-flow: row nowrap;
}

#hobbies,
.name,
.text-error-page {
  align-items: flex-start;
}

.all-circle,
.bg-icon-skill,
.bg-left-bloc,
.bloc-text h1,
.charts-group,
.charts,
.charts h2,
footer,
.iconPrint,
.left-bloc,
.left-subtitle h2,
.right-subtitle,
.text-hobbies,
.title {
  align-items: center;
}

.bg-header {
  justify-content: flex-end;
}

.bg-icon-skill,
.bg-left-bloc h1,
.body,
.charts,
.charts-group,
.charts h2,
footer,
.name,
.text-hobbies,
.text-whoAmI {
  justify-content: center;
}

.all-circle,
#hobbies {
  justify-content: space-around;
}

.bg-left-bloc,
footer,
.right-bloc,
.text-hobbies {
  flex-direction: column;
}

.bg-header {
  flex-direction: row;
}
