.iconPrint {
  right: 0;
  bottom: 0;
  color: $bg-color-1;
  z-index: 2;
  background-color: $bg-color-6;
  border: $bg-color-5 solid 2px;
  border-right: transparent;
  padding: 5px 10px 8px 20px;
  width: 35px;
  height: 46px;
  transition: all 600ms cubic-bezier(1, -0.75, 0.53, 1.68);

  p {
    display: none;
    margin: 0 0 0 15px;
  }
}

.iconPrint:hover {
  width: 180px;
  padding: 5px 20px 8px 20px;
  cursor: pointer;
  text-decoration: none;

  p {
    display: block;
  }
}

.view-pdf {
  width: 100%;
  height: 100vh;
  overflow: clip;
  padding: 0;
  margin-bottom: -9px;
}

.icon-close {
  border: 3px solid $bg-color-9;
  border-radius: 10px;
  padding: 5px 10px;
  color: $bg-color-9;
  bottom: 20px;
  right: 40px;
  transition: all 200ms;
}

.icon-close:hover {
  border: 3px solid $bg-color-9;
  color: $bg-color-9;
}

#bottom-bloc-right {
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}
