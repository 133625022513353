.title {
  padding-left: 10px;
  margin: 30px 0 17px 0;
}

.right-subtitle,
.title {
  color: $text-color-3;
}

.text {
  margin: 0 10px;
}

.icon-subtitle {
  width: 0;
  height: 0;
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 12px solid $bg-color-1;
  margin-right: 10px;
  margin-top: 2px;
  padding-top: 2px;
}

.left-subtitle {
  margin-top: 10px;
  margin-bottom: 8px;
}

.subtitle {
  & span {
    margin-right: 15px;
  }
}

.left-text {
  margin: 0.47rem 10px;
  h3 {
    margin-left: 25px;
  }
  li {
    margin-left: 30px;
  }
}
