.charts {
    margin: 10px;
    width: 80px;
    height: 80px;

    h2 {
        padding-bottom: 5px;
        width: 100%;
        height: 100%;
        transform: rotate(-45deg);
    }
}

.other {
    padding-bottom: 2.5rem;
}