.bg-icon-skill {
  height: 28px;
}

.icon-title,
.little-round {
  height: 50px;
}

#hobbies {
  height: 201px;
}

#bottom-bloc-right {
  height: 202px;
}

.circle,
.circle2,
.circle2-1,
.circle2-2,
.circle2-3,
.circle2-4 {
  height: 300px;
}

.svgIconSkill,
.telescope {
  height: auto;
}

.bloc-error {
  height: 100vh;
}

#home,
.left-bloc,
.bg-left-bloc,
.bg-header {
  height: 100%;
}
