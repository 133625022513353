body {
  box-sizing: border-box;
  overflow-x: hidden;
}

.circle,
.error,
.icon-close,
.text-title-error {
  position: absolute;
}

.bg-point-icon-info,
.bloc-error,
#bottom-bloc-right,
.circle,
footer,
header,
#hobbies,
.left-bloc,
.little-round,
.page-pdf {
  position: relative;
}

//----------    COLOR    ----------//

.bg-left-bloc,
footer,
.name {
  color: $text-color-1;
}

//----------     BOX     ----------//

.bg-left-bloc,
.bloc-text,
.left-bloc,
section {
  box-sizing: border-box;
}

//----------   MARGIN    ----------//

.title-format {
  padding: 5px 0 4px 0;
}
