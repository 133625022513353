.bg-icon-skill {
  width: 28px;
}

.icon-title,
.little-round,
.telescope {
  width: 50px;
}

.all-circle {
  width: 90%;
}

.bloc-error,
#root {
  width: 100vw;
}

.bg-header,
.bg-left-bloc,
.bloc-error,
.bloc-text,
.bloc-text ul li,
footer,
header,
.left-subtitle,
main,
.page-pdf,
section {
  width: 100%;
}

.right-subtitle,
.text-object {
  width: 270px;
}

.circle {
  width: 300px;
}

.charts-group {
  width: 600px;
}

.left-bloc {
  width: 400px;
}

.left-text,
.text p {
  max-width: 500px;
}
