//----------  FONT FAMILY  ----------//

.body,
.error {
  font-family: "Barlow", sans-serif;
}

.error h1 {
  font-family: "blasphem", Arial;
}

//----------  FONT SIZE  ----------//

.text-hobbies {
  font-size: 1rem;
}

.text-bar {
  font-size: 0.75rem;
}

.charts h2 {
  font-size: 0.7rem;
}

.bg-left-bloc,
.text-error {
  font-size: 20px;
}

.iconPrint,
.text-error h3 {
  font-size: 25px;
}

.bg-left-bloc h1,
.icon-close,
.text-error-page,
.title {
  font-size: 30px;
}

.left-subtitle {
  font-size: 15px;
}

.left-text {
  li {
    font-size: 0.8rem;
  }
  h3 {
    font-size: 1rem;
  }
}

//----------  FONT WEIGHT  ----------//

.bg-left-bloc h1,
.charts h2,
.iconPrint p,
.name,
.subtitle,
.text-error h4,
.title {
  font-weight: bold;
}

.text-error h3 {
  font-weight: bolder;
}

//----------  TEXT  ----------//

.bg-left-bloc h1,
.charts h2,
.subtitle,
.name,
.title h1 {
  text-transform: uppercase;
}

// .text {
//   text-align: justify;
// }

.charts h2 {
  text-align: center;
}
